<template>
  <div>
    <form
      @submit.prevent="validationBeforeSubmit"
      v-bind="$attrs"
      v-on="listeners"
    >
      <slot />
    </form>
  </div>
</template>

<script>
export default {
  name: "AForm",
  data() {
    return {
      validMessages: null
    };
  },
  computed: {
    cValidMessages() {
      return this.validMessages;
    },
    listeners() {
      const { input, change, ...listeners } = this.$listeners; // eslint-disable-line no-unused-vars
      return listeners;
    }
  },

  mounted() {
    this.validMessages = null;
  },
  beforeDestroy() {
    this.validMessages = null;
  },
  methods: {
    validationBeforeSubmit() {
      this.validMessages = new Map();
      let { $children } = this;
      for (let child of $children) {
        if (child.validator && typeof child.validator === "object")
          child.validate(false, {
            valid: value => {
              this.validMessages.delete(value.key);
            },
            invalid: value => {
              this.validMessages.set(value.key, {
                message: value.message,
                label: value.label
              });
            }
          });
      }
      this.$emit("submit-form", { el: this.$el, valid: this.cValidMessages });
      this.$emit("update:end-valid", this.cValidMessages);
    }
  }
  // props: {
  //   action: String,
  //   method: String,
  //   enctype: String
  // }
};
</script>
